import {Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import MacroEconomics from "./pages/MacroEconomics";
import React from "react";

export default function AppRoutePaths() {
    return (
        <>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/macro-economics" element={<MacroEconomics/>}/>
            </Routes>
        </>
    )
}
