import React from "react";

export default function MacroEconomics() {
    return (
        <>
            <h1 style={{marginBottom: 0}}>Macro Economics Graphs</h1>
            <p style={{marginTop: 0}}>Source: <a href="https://data.oecd.org/">Data OECD</a></p>

            <h2>Consumer confidence index (CCI)</h2>
            <iframe src="https://data.oecd.org/chart/6SDN" width="100%" height="645" style={{border: 0}}
                    allowFullScreen={true}>
                <a href="https://data.oecd.org/chart/6SDN" target="_blank" rel="noreferrer">
                    OECD Chart: Consumer confidence index (CCI), Amplitude adjusted, Long-term average = 100, Monthly,
                    Jan 1990 – latest</a>
            </iframe>

            <h2>Composite leading indicator (CLI)</h2>
            <iframe src="https://data.oecd.org/chart/6SDI" width="100%" height="645" style={{border: 0}}
                    allowFullScreen={true}><a
                href="https://data.oecd.org/chart/6SDI" target="_blank" rel="noreferrer">OECD
                Chart: Composite leading indicator (CLI), Amplitude adjusted, Long-term average = 100, Monthly, Jan
                1990 – latest</a></iframe>

            <h2>Business confidence index (BCI)</h2>
            <iframe src="https://data.oecd.org/chart/6SDL" width="100%" height="645" style={{border: 0}}
                    allowFullScreen={true}><a
                href="https://data.oecd.org/chart/6SDL" target="_blank" rel="noreferrer">OECD
                Chart: Business confidence index (BCI), Amplitude adjusted, Long-term average = 100, Monthly,
                Jan 1990 – latest</a>
            </iframe>

            <h2>Unemployment rate, Total, % of labour force</h2>
            <iframe src="https://data.oecd.org/chart/6SDW" width="100%" height="645" style={{border: 0}}
                    allowFullScreen={true}><a
                href="https://data.oecd.org/chart/6SDW" target="_blank" rel="noreferrer">OECD
                Chart: Unemployment rate, Total, % of labour force, Monthly, Jan 1997 – latest</a>
            </iframe>

            <h2>Income inequality, Gini coefficient, 0 = complete equality; 1 = complete inequality</h2>
            <iframe src="https://data.oecd.org/chart/6SDV" width="100%" height="645" style={{border: 0}}
                    allowFullScreen={true}><a
                href="https://data.oecd.org/chart/6SDV" target="_blank" rel="noreferrer">OECD
                Chart: Income inequality, Gini coefficient, 0 = complete equality; 1 = complete inequality,
                Annual, 2004 – latest</a></iframe>

            <h2>Household debt, Total, % of net disposable income</h2>
            <iframe src="https://data.oecd.org/chart/6SE7" width="100%" height="645" style={{border: 0}}
                    allowFullScreen={true}><a
                href="https://data.oecd.org/chart/6SE7" target="_blank" rel="noreferrer">OECD
                Chart: Household debt, Total, % of net disposable income, Annual, 1995 – latest</a></iframe>

            <h2>Household savings, Total, % of household disposable income</h2>
            <iframe src="https://data.oecd.org/chart/6SE9" width="100%" height="645" style={{border: 0}}
                    allowFullScreen={true}><a
                href="https://data.oecd.org/chart/6SE9" target="_blank" rel="noreferrer">OECD
                Chart: Household savings, Total, % of household disposable income, Annual, 1995 – latest</a>
            </iframe>

            <h2>Household savings forecast, Net, % of household disposable income</h2>
            <iframe src="https://data.oecd.org/chart/6SE1" width="100%" height="645" style={{border: 0}}
                    allowFullScreen={true}><a
                href="https://data.oecd.org/chart/6SE1" target="_blank" rel="noreferrer">OECD
                Chart: Household savings forecast, Net, % of household disposable income, Quarterly, Q1 1991 –
                latest</a></iframe>

            <h2>Household financial assets, Currency and deposits, %</h2>
            <iframe src="https://data.oecd.org/chart/6SDZ" width="100%" height="645" style={{border: 0}}
                    allowFullScreen={true}><a
                href="https://data.oecd.org/chart/6SDZ" target="_blank" rel="noreferrer">OECD
                Chart: Household financial assets, Currency and deposits, % of total financial assets, Annual,
                1995 – latest</a>
            </iframe>
        </>
    );
}
